
.header {
  z-index: 3;
  .container {
    @include horizonCenterBetween;
    & > * {
      margin-right: 35px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &-item {
    padding: 5px 0;
    background: $yellow;
  }
  &-menu {
    background: $blue-3;
  }
  &-btn {
    @include horizonCenter;
    & > * {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
    &__item {
      padding: 29px 0;
      width: 30px;
      min-height: 20px;
      background-size: 20px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .menu {
    display: flex;
    li {
      margin-right: 50px;
      a {
        padding: 20px 5px;
        color: $white;
        white-space: nowrap;
        &:hover {
          color: $red-2;
        }
      }
      &.active {
        a {
          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            height: 4px;
            left: 0;
            z-index: 1;
            width: 100%;
            background: $red-2;
          }
        }
      }
    }
  }
}

.logo {
  img {
    width: 200px;
  }
}

.address {
  font-weight: 900;
  color: $gray-4;
  width: 380px;
}

.social {
  @include horizonCenter;
  a {
    margin: 0 15px;
    img {
      max-width: 24px;
    }
    &:hover {
      opacity: .7;
    }
  }
}

.phone {
  text-align: right;
  &-schedule {
    margin-top: 8px;
    font-size: .9em;
    font-weight: 500;
  }
}

.tel {
  font-size: 24px;
  font-weight: 700;
  padding-left: 34px;
  white-space: nowrap;
  span {
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: $black;
      transition: .5s;
    }
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    background-position: left center;
    background-repeat: no-repeat;
    background-image: url(../img/icons/phone-red.svg);
    width: 20px;
    height: 20px;
    margin-top: -10px;
    background-size: 20px;
  }
  &:hover {
    color: $red-2;
    span {
      &:after {
        width: 0;
        left: auto;
        right: 0;
      }
    }
  }
}

.wrap-nav {
  .address,
  .social {
    display: none;
  }
}

.user-btn {
  background-image: url(../img/icons/user.svg);
  &:hover {
    opacity: .7;
  }
}

.close-nav,
.open-nav,
.overlay {
  display: none;
}

.search {
  width: 340px;
  &-submit {
    width: 45px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-image: url(../img/icons/search-gray.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px;
    border-radius: 0 $radius-1 $radius-1 0;
    &:hover {
      background-color: $red-2;
      background-image: url(../img/icons/search-white.svg);
    }
  }
  &-field {
    background-color: $white;
    border-radius: $radius-1;
    font-weight: 700;
    width: 100%;
    display: block;
    padding: 12px 45px 12px 20px;
  }
  &-options {
    position: absolute;
    padding: 10px 20px;
    transition: .5s;
    top: 100%;
    left: 0;
    right: 0;
    background-color: $white;
    border-radius: $radius-1;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    transform: translateY(50px);
    overflow: auto;
    max-height: 60vh;
    @include shadow;
  }
  .search-field:focus + .search-options,
  .search-options.open {
    opacity: 1;
    visibility: visible;
    z-index: 3;
    transform: translateY(5px);
  }
}

.options__product {
  margin: 20px 0;
  @include horizonCenter;
  flex-wrap: wrap;
  &--img {
    width: 40px;
    height: 30px;
    @include verticalCenter;
    img {
      max-height: 100%;
    }
  }
  &--title {
    width: calc(100% - 40px);
    padding-left: 15px;
    font-size: 12px;
    line-height: 1.1;
  }
  &__price {
    margin-top: 5px;
    font-weight: 700;
    font-size: 14px;
    display: block;
    width: calc(100% - 40px);
    padding-left: 15px;
    margin-left: 40px;
    color: $black;
    span {
      &:first-child {
        margin-right: 10px;
      }
    }
    &--old {
      font-size: 12px;
      text-decoration: line-through;
      color: $gray-1;
    }
    &--new {
      color: $red-1;
    }
  }
  &:hover {
    color: $red-2;
     a {
       color: $red-2;
     }
  }
}

.cart {
  &-btn {
    background-image: url(../img/icons/shopping-cart-white.svg);
    span {
      position: absolute;
      font-weight: bold;
      font-size: .9em;
      border-radius: 10px;
      background: $red-2;
      color: $white;
      padding: 3px 6px;
      top: 8px;
      right: -7px;
      z-index: 1;
    }
    &:hover {
      opacity: .7;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      height: 4px;
      background: $red-2;
      left: 0;
      width: 0;
      transition: .5s;
    }
    &.active {
      &:after {
        width: 100%;
      }
    }
  }
  &-options {
    position: absolute;
    padding: 10px 20px 20px 20px;
    transition: .5s;
    top: 100%;
    right: 0;
    width: 310px;
    background-color: $white;
    border-radius: $radius-1;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    transform: translateY(50px);
    @include shadow;
    overflow: auto;
    max-height: 60vh;
    &.active {
      opacity: 1;
      visibility: visible;
      z-index: 3;
      transform: translateY(5px);
    }
    .options__product {
      padding-right: 35px;
      margin: 10px 0;
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #E0E0E0;
      &:last-of-type {
        border-bottom: none;
      }
    }
    &__delete {
      position: absolute;
      background: url(../img/icons/close-red.svg) no-repeat center;
      width: 20px;
      height: 20px;
      top: 7px;
      right: 0;
      &:hover {
        opacity: .7;
      }
    }
  }
}

.open-search {
  display: none;
}

@media (max-width: 1199px) {

  .header .menu li {
    margin-right: 25px;
  }

}

@media (max-width: 1023px) {

  .open-search {
    display: block;
    background-image: url(../img/icons/search-white.svg);
  }
  .overlay {
    &.open {
      display: block;
      position: fixed;
      z-index: 9998;
      background: #000000;
      opacity: 0.05;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .wrap-nav {
    display: block;
    position: absolute;
    width: 200px;
    top: 5px;
    left: 20px;
    z-index: 9999;
    overflow: auto;
    visibility: hidden;
    opacity: 0;
    padding: 20px 10px 30px 10px;
    margin: 0;
    -webkit-overflow-scrolling: touch;
    background: $white;
    border-radius: $radius-1;
    transform: translateY(100px);
    transition: all 0.5s ease;
    &.open{
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.15);
    }
    .address {
      text-align: center;
      font-size: 12px;
      margin: 20px 0 30px 0;
      display: block;
      width: auto;
    }
    .social {
      display: flex;
      justify-content: center;
    }
    .menu {
      padding-bottom: 15px;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      li {
        margin: 15px 0;
        a {
          color: $black;
          padding: 0;
          &:before, &:after {
            display: none;
          }
        }
      }
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 30px;
        right: 30px;
        background: #e0e0e0;
        height: 1px;
      }
    }
  }
  .search {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $white;
    transform: translateY(-100px);
    -webkit-overflow-scrolling: touch;
    padding: 30px 10px;
    margin: 0;
    visibility: hidden;
    opacity: 0;
    z-index: 9999;
    transition: .5s;
    &.open {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.15);
    }
    &-field {
      border-bottom: 1px solid #E0E0E0;
    }
    &-options {
      left: 10px;
      right: 10px;
      width: auto;
    }
  }
  .open-nav {
    display: block;
    background-image: url(../img/icons/menu.svg);
    background-size: 30px;
  }
  .header-item {
    .address {
      display: none;
    }
    .social {
      display: none;
    }
  }
  .logo {
    img {
      width: 130px;
    }
  }
  .tel {
    font-size: 20px;
  }

}

@media (max-width: 735px) {

  .logo {
    img {
      width: 100px;
    }
  }
  .phone {
    &-schedule {
      font-size: .75em;
      margin-top: 5px;
    }

  }
  .tel {
    padding-left: 20px;
    font-size: 14px;
    &:before {
      width: 14px;
      height: 14px;
      margin-top: -7px;
      background-size: 12px;
    }
  }
  .header .container>* {
    margin-right: 15px;
  }
  .cart-options {
    right: -60px;
    width: 300px;
  }

}