
.news {

  &-preview {
    display: flex;
    margin-bottom: 30px;
    background-color: $white;
    border-radius: $radius-1;
    @include shadow;
    overflow: hidden;
    &__img {
      width: 45%;
      overflow: hidden;
      min-height: 100%;
      &:before {
        content: '';
        padding-top: 70%;
        display: block;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        @include image-size;
      }
    }
    &__text {
      width: 55%;
      padding: 35px 35px 35px 9%;
      .text {
        margin-bottom: 0;
      }
    }

    //hover
    &:hover {
      a {
        color: $red-2;
      }
      .news-preview__img {
        img {
          transform: scale(1.1);
        }
      }
    }
  }
}

.date {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: .9em;
  color: #E0E0E0;
  padding-left: 24px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 14px;
    height: 14px;
    background: url(../img/icons/time-gray.svg) no-repeat center;
    background-size: 14px;
  }
}

@media (max-width: 1023px) {

  .news {
    &-preview {
      &__text {
        padding: 35px 20px;
      }
    }
  }

}
@media (max-width: 735px) {

  .news {
    &-preview {
      display: block;
      margin-bottom: 20px;
      &__img {
        width: 100%;
        height: 180px;
      }
      &__text {
        padding: 20px;
        width: 100%;
      }
    }
  }

}