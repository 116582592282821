@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url(../../../node_modules/swiper/css/swiper.min.css);
@import url(../../../node_modules/normalize.css/normalize.css);
@import url(../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css);
@import url(../../../node_modules/jquery-nice-select/css/nice-select.css);
* {
  position: relative;
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

a, button, span {
  display: inline-block; }

input, textarea {
  display: block;
  width: 100%; }

table {
  width: 100%;
  border-collapse: collapse; }

a {
  background-color: transparent;
  text-decoration: none; }

img {
  border: none;
  max-width: 100%;
  height: auto; }

a, button {
  outline: 0;
  cursor: pointer; }

li {
  list-style: none; }

button {
  background: 0 0;
  border: none; }

a, button, input, textarea {
  font-weight: inherit;
  -webkit-appearance: none;
  transition: all .5s ease; }

:after, :before {
  box-sizing: border-box; }

textarea {
  resize: none;
  outline: 0;
  border: none;
  -webkit-appearance: none;
  border-radius: 0;
  background: 0 0; }

input {
  outline: 0;
  border: none;
  -webkit-appearance: none;
  border-radius: 0;
  background: 0 0; }

input[type=number] {
  -moz-appearance: textfield; }

input::-webkit-inner-spin-button, input::-webkit-outer-spin-button {
  -webkit-appearance: none; }

input[type=submit] {
  cursor: pointer;
  -webkit-appearance: none;
  display: inline-block; }

input[type=search]::-webkit-search-cancel-button {
  background: 0 0;
  display: none; }

.text-align-left {
  text-align: left; }

.text-align-center {
  text-align: center; }

.text-align-right {
  text-align: right; }

.full-img img {
  width: 100%; }

figure {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 5px;
  font-size: .75em;
  opacity: .8; }

[hidden] {
  display: none !important; }

.img-full {
  width: 100%; }

.clearfix:after {
  content: '';
  clear: both;
  display: block; }

@font-face {
  font-family: 'Roboto', sans-serif; }

.list-page a {
  display: block;
  margin: 5px 0; }

h1, .h1 {
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 50px; }

h2, .h2 {
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 50px; }
  @media (max-width: 1023px) {
    h2, .h2 {
      font-size: 22px; } }
  @media (max-width: 735px) {
    h2, .h2 {
      font-size: 18px;
      margin-bottom: 25px; } }

h3, .h3 {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 30px; }

h4, .h4 {
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 20px; }

h5, .h5 {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px; }

h6, .h6 {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 20px; }

.subtitle {
  display: block;
  font-size: 14px;
  padding-top: 10px;
  font-weight: 400; }

.title-combo-nav {
  padding-right: 100px; }

a, button, input, textarea {
  color: #000; }

.link {
  color: #2F80ED;
  text-decoration: underline; }
  .link:hover {
    text-decoration: none; }

.text {
  margin-bottom: 30px; }
  .text > * + * {
    margin-top: 15px; }

body {
  font-family: "Roboto", sans-serif;
  color: #000;
  font-style: normal;
  font-weight: normal;
  background: #fff;
  font-size: 16px;
  line-height: 1.2; }
  @media (max-width: 1023px) {
    body {
      font-size: 14px; } }

.wrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh; }

header {
  width: 100%; }

main {
  padding: 25px 0;
  flex: auto;
  display: block; }

footer {
  margin: auto auto 0 auto;
  width: 100%; }

.container,
.container-fluid {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  z-index: 2; }

.container {
  max-width: 1200px; }

.container-fluid {
  max-width: 1920px; }

.btn-row {
  text-align: center; }
  .btn-row .btn {
    margin: 10px; }

.column {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -20px; }
  .column .column-2 {
    width: 50%;
    padding: 10px 20px; }
  .column .column-3 {
    max-width: 320px;
    width: 33.3%;
    padding: 20px; }

.fancybox-navigation {
  position: static; }

.swiper-button {
  outline: none;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-color: #D4DCE5;
  background-position: center;
  background-image: url(../img/icons/arrow-next--black.svg);
  background-size: 10px;
  width: 30px;
  height: 30px;
  transition: .5s; }
  .swiper-button:hover {
    background-color: #BDBDBD; }
  .swiper-button.swiper-button-prev {
    transform: scaleX(-1); }
  .swiper-button:before, .swiper-button:after {
    display: none; }

.swiper-pagination {
  position: absolute;
  width: 100%;
  text-align: center;
  margin-top: 50px; }
  .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    opacity: 1;
    margin: 0 8px;
    outline: none;
    border: 1px solid #828282;
    background: transparent; }
    .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: #BE2E2E; }

.breadcrumbs {
  padding-top: 20px;
  margin-bottom: 45px;
  font-size: 12px;
  font-weight: 500; }
  .breadcrumbs span {
    display: inline-block;
    vertical-align: top;
    padding-right: 20px;
    margin-right: 10px; }
    .breadcrumbs span:last-of-type {
      padding-right: 0;
      margin-right: 0; }
      .breadcrumbs span:last-of-type:after {
        display: none; }
    .breadcrumbs span:after {
      content: '/';
      position: absolute;
      top: 0;
      right: 0;
      color: #BDBDBD; }
    .breadcrumbs span a {
      color: #BDBDBD; }
      .breadcrumbs span a:hover {
        color: #BE2E2E; }
  @media (max-width: 735px) {
    .breadcrumbs {
      margin-bottom: 30px; } }

.block {
  margin: 45px 0; }
  @media (max-width: 735px) {
    .block {
      margin: 35px 0; } }

.slider-nav {
  position: absolute;
  top: 5px;
  right: 0;
  display: flex; }
  .slider-nav .swiper-button {
    margin: 0 10px;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto; }
  @media (max-width: 735px) {
    .slider-nav {
      top: -7px; } }

.tabs_content {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  pointer-events: none; }
  .tabs_content.active {
    opacity: 1;
    position: relative;
    z-index: 1;
    pointer-events: auto; }

.wrap_tabs__products {
  padding-bottom: 35px; }
  .wrap_tabs__products h2 {
    font-size: 20px; }
  .wrap_tabs__products .tabs_caption {
    border-bottom: 1px solid #BDBDBD; }
  .wrap_tabs__products .tabs_content {
    right: auto;
    width: 560px;
    max-width: 100%; }

.tabs_caption {
  padding-right: 100px;
  margin-bottom: 45px;
  display: flex; }
  .tabs_caption li {
    margin-bottom: 0;
    margin-right: 35px;
    cursor: pointer;
    transition: .5s; }
    .tabs_caption li:last-child {
      margin-right: 0; }
    .tabs_caption li h2 {
      margin-bottom: 0;
      color: #BDBDBD;
      transition: .5s;
      padding-bottom: 10px; }
      .tabs_caption li h2:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        background: #BE2E2E;
        height: 4px;
        transition: .5s; }
    .tabs_caption li .slider-nav {
      opacity: 0;
      pointer-events: none; }
    .tabs_caption li:hover h2 {
      color: #959595; }
    .tabs_caption li.active {
      color: #BE2E2E; }
      .tabs_caption li.active h2 {
        color: #BE2E2E; }
        .tabs_caption li.active h2:after {
          width: 100%;
          right: auto;
          left: 0; }
      .tabs_caption li.active .slider-nav {
        opacity: 1;
        pointer-events: auto; }

@media (max-width: 735px) {
  .slider-products--combo .product-preview {
    height: calc(50% - 5px);
    margin-bottom: 5px; }
    .slider-products--combo .product-preview:nth-child(2) {
      margin-bottom: 0; }
  .wrap_tabs .slider-nav {
    display: none; }
  .wrap_tabs .tabs_caption {
    padding-right: 0;
    margin-bottom: 25px; }
    .wrap_tabs .tabs_caption li {
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column; }
      .wrap_tabs .tabs_caption li:last-child {
        margin-right: 0; }
      .wrap_tabs .tabs_caption li h2 {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        font-size: 14px;
        padding-bottom: 5px; }
        .wrap_tabs .tabs_caption li h2:after {
          height: 1px; } }

.white-space--nowrap {
  white-space: nowrap; }

input::-webkit-input-placeholder {
  color: #BDBDBD; }

input::-moz-placeholder {
  color: #BDBDBD; }

input:-moz-placeholder {
  color: #BDBDBD; }

input:-ms-input-placeholder {
  color: #BDBDBD; }

textarea::-webkit-input-placeholder {
  color: #BDBDBD; }

textarea::-moz-placeholder {
  color: #BDBDBD; }

textarea:-moz-placeholder {
  color: #BDBDBD; }

textarea:-ms-input-placeholder {
  color: #BDBDBD; }

input:focus::-webkit-input-placeholder {
  color: transparent;
  transition: all .5s ease; }

input:focus::-moz-placeholder {
  color: transparent;
  transition: all .5s ease; }

input:focus:-moz-placeholder {
  color: transparent;
  transition: all .5s ease; }

input:focus:-ms-input-placeholder {
  color: transparent;
  transition: all .5s ease; }

textarea:focus::-webkit-input-placeholder {
  color: transparent;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease; }

textarea:focus::-moz-placeholder {
  color: transparent;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease; }

textarea:focus:-moz-placeholder {
  color: transparent;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease; }

textarea:focus:-ms-input-placeholder {
  color: transparent;
  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  -moz-transition: all .5s ease;
  transition: all .5s ease; }

.btn {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  padding: 13px 19px;
  border: 1px solid #828282;
  border-radius: 30px; }
  .btn-indent {
    margin: 45px 0;
    min-width: 150px; }
  .btn-arrow span {
    padding-right: 30px; }
    .btn-arrow span:before {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      background-image: url(../img/icons/arrow-long.svg);
      background-repeat: no-repeat;
      background-position: center;
      width: 17px;
      height: 10px;
      margin-top: -5px;
      transition: .5s; }
  .btn-arrow:hover span:before {
    margin-right: -5px;
    background-image: url(../img/icons/arrow-long--white.svg); }
  .btn-basket {
    white-space: nowrap; }
    .btn-basket span {
      padding-left: 22px; }
      .btn-basket span:before {
        content: '';
        position: absolute;
        top: 50%;
        width: 12px;
        height: 12px;
        background-size: 12px;
        margin-top: -8px;
        left: 0;
        transition: .5s;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(../img/icons/shopping-cart-red.svg); }
    .btn-basket:hover span:before {
      background-image: url(../img/icons/shopping-cart-white.svg); }
  .btn:hover {
    background: #BE2E2E;
    border-color: #BE2E2E;
    color: #fff; }
  .btn-blue {
    font-size: 12px;
    padding-top: 20px;
    padding-bottom: 20px;
    border: none;
    color: #fff;
    background: #314A8D; }
    .btn-blue:hover {
      background: #2F80ED; }

.form-field {
  font-weight: 500;
  font-size: 12px;
  display: block; }
  .form-field input {
    font-weight: 500;
    font-size: 12px;
    border-radius: 50px;
    border: 1px solid #BDBDBD;
    color: #000;
    padding: 13px 20px; }
    .form-field input:focus {
      border-color: #133351; }
  .form-field input, .form-field .nice-select {
    margin: 10px 0 25px 0;
    display: block;
    width: 100%; }

.consent {
  font-size: 12px; }
  .consent label {
    display: block;
    padding-left: 28px; }
    .consent label input {
      position: absolute;
      top: 0;
      left: 0;
      width: 14px;
      height: 14px;
      cursor: pointer;
      z-index: 1; }
  .consent-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 14px;
    height: 14px;
    border: 1px solid #BDBDBD;
    transition: .5s; }
  .consent input:checked + .consent-icon {
    background: #314A8D;
    border-color: #314A8D; }
  .consent input:hover + .consent-icon {
    border-color: #314A8D; }

.form_radio input[type=radio] {
  display: none; }

.form_radio label {
  display: inline-block;
  cursor: pointer;
  padding-left: 30px;
  user-select: none; }
  .form_radio label:before, .form_radio label:after {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    top: -1px;
    border-radius: 20px;
    border: 1px solid #BDBDBD;
    transition: .5s; }
  .form_radio label:after {
    width: 14px;
    height: 14px;
    top: 2px;
    left: 3px;
    background: #BE2E2E;
    opacity: 0; }

.form_radio input[type=radio]:checked + label:before,
.form_radio input[type=radio]:checked + label:after {
  opacity: 1;
  border-color: #BE2E2E; }

.nice-select {
  border-color: #BDBDBD; }
  .nice-select .list {
    width: 100%; }

.modal, .modal-content > * {
  transform: translateY(150px);
  opacity: 0;
  -webkit-transition: opacity .5s, -webkit-transform .5s;
  transition: opacity .5s, -webkit-transform .5s;
  -o-transition: opacity .5s, -o-transform .5s;
  -moz-transition: transform .5s, opacity .5s, -moz-transform .5s;
  transition: transform .5s, opacity .5s;
  transition: transform .5s, opacity .5s, -webkit-transform .5s, -moz-transform .5s, -o-transform .5s; }

.fancybox-slide--current .modal, .fancybox-slide--current .modal-content > * {
  opacity: 1;
  transform: translateY(0); }

.modal {
  display: none;
  max-width: 100%;
  border-radius: 10px;
  background-color: #fff;
  overflow: visible;
  padding: 60px 20px;
  width: 440px;
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.15); }
  .modal-content {
    width: 260px;
    max-width: 100%;
    margin: 0 auto; }
  .modal h3 {
    margin-bottom: 20px; }
  .modal-movement {
    margin-bottom: 40px;
    color: #BDBDBD;
    font-size: .9em; }
  .modal .btn {
    width: 100%; }
  .modal .consent {
    width: 200px;
    margin: 25px auto 0 auto;
    max-width: 100%; }

.forgot-password {
  text-align: center;
  margin-top: 25px;
  font-size: 12px; }

.header {
  z-index: 3; }
  .header .container {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .header .container > * {
      margin-right: 35px; }
      .header .container > *:last-child {
        margin-right: 0; }
  .header-item {
    padding: 5px 0;
    background: #FFF844; }
  .header-menu {
    background: #133351; }
  .header-btn {
    display: flex;
    align-items: center; }
    .header-btn > * {
      margin-right: 20px; }
      .header-btn > *:last-child {
        margin-right: 0; }
    .header-btn__item {
      padding: 29px 0;
      width: 30px;
      min-height: 20px;
      background-size: 20px;
      background-position: center;
      background-repeat: no-repeat; }
  .header .menu {
    display: flex; }
    .header .menu li {
      margin-right: 50px; }
      .header .menu li a {
        padding: 20px 5px;
        color: #fff;
        white-space: nowrap; }
        .header .menu li a:hover {
          color: #BE2E2E; }
      .header .menu li.active a:after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 4px;
        left: 0;
        z-index: 1;
        width: 100%;
        background: #BE2E2E; }

.logo img {
  width: 200px; }

.address {
  font-weight: 900;
  color: #4F4F4F;
  width: 380px; }

.social {
  display: flex;
  align-items: center; }
  .social a {
    margin: 0 15px; }
    .social a img {
      max-width: 24px; }
    .social a:hover {
      opacity: .7; }

.phone {
  text-align: right; }
  .phone-schedule {
    margin-top: 8px;
    font-size: .9em;
    font-weight: 500; }

.tel {
  font-size: 24px;
  font-weight: 700;
  padding-left: 34px;
  white-space: nowrap; }
  .tel span:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #000;
    transition: .5s; }
  .tel:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    background-position: left center;
    background-repeat: no-repeat;
    background-image: url(../img/icons/phone-red.svg);
    width: 20px;
    height: 20px;
    margin-top: -10px;
    background-size: 20px; }
  .tel:hover {
    color: #BE2E2E; }
    .tel:hover span:after {
      width: 0;
      left: auto;
      right: 0; }

.wrap-nav .address,
.wrap-nav .social {
  display: none; }

.user-btn {
  background-image: url(../img/icons/user.svg); }
  .user-btn:hover {
    opacity: .7; }

.close-nav,
.open-nav,
.overlay {
  display: none; }

.search {
  width: 340px; }
  .search-submit {
    width: 45px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-image: url(../img/icons/search-gray.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 15px;
    border-radius: 0 4px 4px 0; }
    .search-submit:hover {
      background-color: #BE2E2E;
      background-image: url(../img/icons/search-white.svg); }
  .search-field {
    background-color: #fff;
    border-radius: 4px;
    font-weight: 700;
    width: 100%;
    display: block;
    padding: 12px 45px 12px 20px; }
  .search-options {
    position: absolute;
    padding: 10px 20px;
    transition: .5s;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    transform: translateY(50px);
    overflow: auto;
    max-height: 60vh;
    box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.15); }
  .search .search-field:focus + .search-options,
  .search .search-options.open {
    opacity: 1;
    visibility: visible;
    z-index: 3;
    transform: translateY(5px); }

.options__product {
  margin: 20px 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .options__product--img {
    width: 40px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .options__product--img img {
      max-height: 100%; }
  .options__product--title {
    width: calc(100% - 40px);
    padding-left: 15px;
    font-size: 12px;
    line-height: 1.1; }
  .options__product__price {
    margin-top: 5px;
    font-weight: 700;
    font-size: 14px;
    display: block;
    width: calc(100% - 40px);
    padding-left: 15px;
    margin-left: 40px;
    color: #000; }
    .options__product__price span:first-child {
      margin-right: 10px; }
    .options__product__price--old {
      font-size: 12px;
      text-decoration: line-through;
      color: #BDBDBD; }
    .options__product__price--new {
      color: #EB5757; }
  .options__product:hover {
    color: #BE2E2E; }
    .options__product:hover a {
      color: #BE2E2E; }

.cart-btn {
  background-image: url(../img/icons/shopping-cart-white.svg); }
  .cart-btn span {
    position: absolute;
    font-weight: bold;
    font-size: .9em;
    border-radius: 10px;
    background: #BE2E2E;
    color: #fff;
    padding: 3px 6px;
    top: 8px;
    right: -7px;
    z-index: 1; }
  .cart-btn:hover {
    opacity: .7; }
  .cart-btn:after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 4px;
    background: #BE2E2E;
    left: 0;
    width: 0;
    transition: .5s; }
  .cart-btn.active:after {
    width: 100%; }

.cart-options {
  position: absolute;
  padding: 10px 20px 20px 20px;
  transition: .5s;
  top: 100%;
  right: 0;
  width: 310px;
  background-color: #fff;
  border-radius: 4px;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transform: translateY(50px);
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.15);
  overflow: auto;
  max-height: 60vh; }
  .cart-options.active {
    opacity: 1;
    visibility: visible;
    z-index: 3;
    transform: translateY(5px); }
  .cart-options .options__product {
    padding-right: 35px;
    margin: 10px 0;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #E0E0E0; }
    .cart-options .options__product:last-of-type {
      border-bottom: none; }
  .cart-options__delete {
    position: absolute;
    background: url(../img/icons/close-red.svg) no-repeat center;
    width: 20px;
    height: 20px;
    top: 7px;
    right: 0; }
    .cart-options__delete:hover {
      opacity: .7; }

.open-search {
  display: none; }

@media (max-width: 1199px) {
  .header .menu li {
    margin-right: 25px; } }

@media (max-width: 1023px) {
  .open-search {
    display: block;
    background-image: url(../img/icons/search-white.svg); }
  .overlay.open {
    display: block;
    position: fixed;
    z-index: 9998;
    background: #000000;
    opacity: 0.05;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .wrap-nav {
    display: block;
    position: absolute;
    width: 200px;
    top: 5px;
    left: 20px;
    z-index: 9999;
    overflow: auto;
    visibility: hidden;
    opacity: 0;
    padding: 20px 10px 30px 10px;
    margin: 0;
    -webkit-overflow-scrolling: touch;
    background: #fff;
    border-radius: 4px;
    transform: translateY(100px);
    transition: all 0.5s ease; }
    .wrap-nav.open {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.15); }
    .wrap-nav .address {
      text-align: center;
      font-size: 12px;
      margin: 20px 0 30px 0;
      display: block;
      width: auto; }
    .wrap-nav .social {
      display: flex;
      justify-content: center; }
    .wrap-nav .menu {
      padding-bottom: 15px;
      flex-direction: column;
      justify-content: center;
      text-align: center; }
      .wrap-nav .menu li {
        margin: 15px 0; }
        .wrap-nav .menu li a {
          color: #000;
          padding: 0; }
          .wrap-nav .menu li a:before, .wrap-nav .menu li a:after {
            display: none; }
      .wrap-nav .menu:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 30px;
        right: 30px;
        background: #e0e0e0;
        height: 1px; }
  .search {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    transform: translateY(-100px);
    -webkit-overflow-scrolling: touch;
    padding: 30px 10px;
    margin: 0;
    visibility: hidden;
    opacity: 0;
    z-index: 9999;
    transition: .5s; }
    .search.open {
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
      box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.15); }
    .search-field {
      border-bottom: 1px solid #E0E0E0; }
    .search-options {
      left: 10px;
      right: 10px;
      width: auto; }
  .open-nav {
    display: block;
    background-image: url(../img/icons/menu.svg);
    background-size: 30px; }
  .header-item .address {
    display: none; }
  .header-item .social {
    display: none; }
  .logo img {
    width: 130px; }
  .tel {
    font-size: 20px; } }

@media (max-width: 735px) {
  .logo img {
    width: 100px; }
  .phone-schedule {
    font-size: .75em;
    margin-top: 5px; }
  .tel {
    padding-left: 20px;
    font-size: 14px; }
    .tel:before {
      width: 14px;
      height: 14px;
      margin-top: -7px;
      background-size: 12px; }
  .header .container > * {
    margin-right: 15px; }
  .cart-options {
    right: -60px;
    width: 300px; } }

.footer {
  padding: 70px 0 35px 0;
  color: #fff;
  font-size: .9em;
  background: #4f4f4f; }
  .footer a, .footer button, .footer input {
    color: #fff; }
  .footer .container {
    display: flex;
    align-items: flex-start; }
    .footer .container > * {
      margin-bottom: 35px;
      margin-right: 35px; }
      .footer .container > *:last-of-type {
        margin-right: 0; }
  .footer .menu li {
    margin: 0 0 20px 0; }
    .footer .menu li a {
      font-weight: 500;
      color: #fff; }
      .footer .menu li a:hover {
        opacity: .7; }
  .footer-menu {
    min-width: 200px;
    flex: auto; }
    .footer-menu:nth-child(1) .menu {
      column-count: 2;
      column-gap: 35px;
      page-break-inside: avoid;
      break-inside: avoid; }
      .footer-menu:nth-child(1) .menu li {
        page-break-inside: avoid;
        break-inside: avoid; }
  .footer-contacts {
    width: 300px; }
    .footer-contacts__item {
      margin: 20px 0;
      padding-left: 45px; }
      .footer-contacts__item img {
        position: absolute;
        top: 50%;
        left: 0;
        max-width: 25px;
        transform: translateY(-50%); }
  .footer .social {
    display: none; }

@media (max-width: 1023px) {
  .footer {
    padding: 50px 0 0 0; }
    .footer .container {
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .footer .container .footer-contacts {
        margin-right: 0;
        margin-bottom: 20px; }
    .footer-menu {
      display: none; }
    .footer-contacts {
      max-width: 100%;
      width: 300px; }
    .footer h6 {
      text-align: center; }
    .footer .social {
      display: flex;
      justify-content: center; }
      .footer .social a {
        margin: 0 15px; } }

.slider-home .swiper-slide {
  height: auto;
  min-height: 500px;
  display: flex;
  overflow: hidden;
  border-radius: 4px; }

.slider-home .swiper-pagination {
  width: 45%;
  bottom: 50px; }
  .slider-home .swiper-pagination-bullet {
    border-color: #fff; }
    .slider-home .swiper-pagination-bullet-active {
      border-color: #BE2E2E; }

.slider-home__name {
  width: 45%;
  padding: 60px 60px 130px 100px;
  color: #fff;
  background-color: #27AE60; }
  .slider-home__name span {
    font-weight: bold;
    font-size: 48px; }

.slider-home__product {
  padding: 60px;
  width: 55%;
  background-color: #FFF844;
  display: flex;
  align-items: flex-end; }
  .slider-home__product__img {
    width: 65%; }
  .slider-home__product__info {
    width: 35%; }
  .slider-home__product .product-mark {
    margin-bottom: 25px; }
  .slider-home__product__title {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 20px; }
    .slider-home__product__title:hover {
      color: #BE2E2E; }
  .slider-home__product__price {
    font-weight: 900;
    font-size: 30px;
    white-space: nowrap; }

.slider-home .swiper-button {
  opacity: 1;
  background-color: transparent;
  background-image: url(../img/icons/arrow-next--black.svg), url(../img/next-mask.png);
  background-repeat: no-repeat, no-repeat;
  background-size: 15px, 100%;
  width: 46px;
  height: 106px;
  margin-top: -53px;
  border-radius: 0; }
  .slider-home .swiper-button.swiper-button-prev {
    left: 0; }
  .slider-home .swiper-button.swiper-button-next {
    right: 0; }

@media (max-width: 1023px) {
  .slider-home {
    padding-bottom: 35px; }
    .slider-home .swiper-button {
      display: none; }
    .slider-home .swiper-slide {
      display: block;
      min-height: auto; }
    .slider-home__name {
      width: 100%;
      display: block;
      padding: 80px 30px; }
      .slider-home__name span {
        font-size: 34px; }
    .slider-home__product {
      padding: 30px;
      width: 100%; }
      .slider-home__product__img img {
        max-height: 260px; }
    .slider-home .swiper-pagination {
      width: 100%;
      bottom: 0; }
      .slider-home .swiper-pagination-bullet {
        border-color: #959595; }
  .product-mark img {
    max-width: 90px; } }

@media (max-width: 735px) {
  .slider-home__name {
    padding: 35px 30px; }
    .slider-home__name span {
      font-size: 24px;
      max-width: 80%; }
  .slider-home__product__title {
    font-size: 12px;
    margin-bottom: 10px; }
  .slider-home__product__price {
    font-size: 18px; }
  .slider-home .product-mark {
    margin-bottom: 10px; }
    .slider-home .product-mark img {
      max-width: 60px; } }

.wrap-map {
  padding: 100px 0;
  margin-bottom: -25px; }
  .wrap-map iframe {
    display: block;
    position: absolute;
    top: 0;
    left: 0; }
  .wrap-map .container {
    pointer-events: none; }

.map-contacts {
  width: 450px;
  max-width: calc(100% - 40px);
  margin: 100px 20px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.15);
  padding: 10px 50px;
  pointer-events: auto; }
  .map-contacts__item {
    margin: 45px 0;
    padding-left: 45px;
    font-weight: 500; }
    .map-contacts__item img {
      position: absolute;
      top: 50%;
      left: 0;
      max-width: 25px;
      transform: translateY(-50%); }

@media (max-width: 1023px) {
  .wrap-map {
    padding: 50px 0; }
  .map-contacts {
    margin: 0;
    max-width: 100%; }
    .map-contacts__item {
      margin: 20px 0; } }

@media (max-width: 735px) {
  .wrap-map {
    padding: 200px 0 20px 0; }
  .map-contacts {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px; }
    .map-contacts__item {
      padding-left: 30px; } }

.news-preview {
  display: flex;
  margin-bottom: 30px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.15);
  overflow: hidden; }
  .news-preview__img {
    width: 45%;
    overflow: hidden;
    min-height: 100%; }
    .news-preview__img:before {
      content: '';
      padding-top: 70%;
      display: block; }
    .news-preview__img img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      object-position: center;
      transition: .5s; }
  .news-preview__text {
    width: 55%;
    padding: 35px 35px 35px 9%; }
    .news-preview__text .text {
      margin-bottom: 0; }
  .news-preview:hover a {
    color: #BE2E2E; }
  .news-preview:hover .news-preview__img img {
    transform: scale(1.1); }

.date {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: .9em;
  color: #E0E0E0;
  padding-left: 24px; }
  .date:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 14px;
    height: 14px;
    background: url(../img/icons/time-gray.svg) no-repeat center;
    background-size: 14px; }

@media (max-width: 1023px) {
  .news-preview__text {
    padding: 35px 20px; } }

@media (max-width: 735px) {
  .news-preview {
    display: block;
    margin-bottom: 20px; }
    .news-preview__img {
      width: 100%;
      height: 180px; }
    .news-preview__text {
      padding: 20px;
      width: 100%; } }

.slider-news {
  margin: -10px; }
  .slider-news .swiper-container {
    padding: 10px; }
    .slider-news .swiper-container .swiper-slide {
      height: auto;
      transition: .5s; }
  .slider-news .news-preview {
    height: 100%;
    flex-direction: column;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1); }
    .slider-news .news-preview__img {
      width: 100%;
      height: 170px;
      min-height: auto; }
      .slider-news .news-preview__img:before {
        display: none; }
    .slider-news .news-preview__text {
      width: 100%;
      padding: 30px; }

@media (max-width: 735px) {
  .slider-news .swiper-container .swiper-slide {
    width: 230px; }
  .slider-news .news-preview__img {
    height: 100px; }
  .slider-news .news-preview__text {
    padding: 20px; }
    .slider-news .news-preview__text a {
      font-size: .9em; } }

.subscription {
  background: #f9f9f9; }
  .subscription-bg {
    position: absolute;
    right: 50%;
    top: 0;
    height: 100%;
    width: 100%;
    max-width: 620px; }
    .subscription-bg img {
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      object-position: center;
      transition: .5s; }
  .subscription-content {
    width: 50%;
    float: right;
    padding: 70px; }
    .subscription-content__block {
      width: 250px;
      margin: 0 auto; }
  .subscription-form .subscription-email {
    width: 100%;
    display: block;
    margin-bottom: 35px;
    border-bottom: 1px solid #000;
    padding: 10px 0 10px 35px;
    background: url(../img/icons/mail-gray.svg) no-repeat left center; }

@media (max-width: 735px) {
  .subscription-bg {
    display: none; }
  .subscription-content {
    width: 100%;
    float: none;
    padding: 30px 0;
    text-align: center;
    justify-content: center; } }

.category {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px; }
  .category-item {
    min-height: 100px;
    padding: 25px 35px;
    width: calc(33.3% - 40px);
    margin: 0 20px 20px 20px;
    box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: top left;
    background-image: url(../img/catalog/catalog.svg);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .category-item__name {
      font-size: .9em;
      width: calc(100% - 30px);
      padding-right: 15px;
      max-width: 200px;
      text-transform: uppercase;
      font-weight: 700; }
    .category-item__icon {
      width: 30px;
      text-align: center; }
      .category-item__icon img {
        transition: .5s; }
    .category-item:hover {
      color: #BE2E2E; }
      .category-item:hover .category-item__icon img {
        transform: scaleX(-1); }
  .category-head {
    background: #FAFAFA;
    padding: 45px 60px;
    margin-bottom: 45px; }
    .category-head__title {
      padding-left: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-transform: uppercase;
      color: #959595;
      font-size: 12px;
      font-weight: 700; }
      .category-head__title:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 0;
        background: url(../img/icons/arrow-long--gray-left.svg) no-repeat center;
        width: 18px;
        height: 10px;
        transition: .5s; }
      .category-head__title .category-item__icon {
        width: 20px; }
      .category-head__title:hover:before {
        transform: translateX(-10px); }
    .category-head ul {
      column-count: 4;
      column-gap: 35px; }
      .category-head ul li {
        margin-bottom: 20px; }
        .category-head ul li a {
          font-size: .9em;
          color: #696969; }
          .category-head ul li a:hover {
            color: #000;
            text-decoration: underline; }

.category-head__title + ul {
  margin-top: 35px; }

@media (max-width: 1023px) {
  .category {
    margin-right: -10px;
    margin-left: -10px; }
    .category-item {
      min-height: auto;
      padding: 20px 15px;
      width: calc(33.3% - 20px);
      margin: 0 10px 10px 10px; }
    .category-head {
      padding: 30px 20px;
      margin-bottom: 35px; }
      .category-head ul {
        column-count: 3;
        column-gap: 30px; } }

@media (max-width: 735px) {
  .category-head ul {
    column-count: 2;
    column-gap: 5px; }
    .category-head ul li {
      margin-bottom: 10px; }
      .category-head ul li a {
        font-size: .8em; } }

@media (max-width: 640px) {
  .category-item {
    width: calc(50% - 20px); } }

@media (max-width: 530px) {
  .category-item {
    width: calc(100% - 20px); } }

.catalog {
  margin-right: -20px;
  margin-left: -20px;
  display: flex;
  flex-wrap: wrap; }
  .catalog-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: flex-start; }
    .catalog-head h2 {
      margin-right: 10px; }

.product-preview {
  width: calc(25% - 40px);
  margin: 0 20px 40px 20px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.15);
  padding: 30px; }
  .product-preview__img {
    height: 140px;
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .product-preview__img img {
      max-height: 100%; }
  .product-preview__title {
    font-size: .9em;
    margin-bottom: 15px; }
    .product-preview__title:hover {
      color: #BE2E2E; }
  .product-preview__price--old {
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 6px;
    text-decoration: line-through;
    white-space: nowrap; }
  .product-preview__price--new {
    color: #EB5757;
    font-weight: 900;
    font-size: 16px;
    margin-bottom: 20px;
    white-space: nowrap; }

.vendor-code {
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 3px;
  color: #BDBDBD; }

.pagination {
  font-weight: 500;
  margin: 45px 0;
  display: flex;
  align-items: center;
  justify-content: center; }
  .pagination > * {
    margin: 0 10px; }
  .pagination a {
    color: #BDBDBD; }
    .pagination a:hover {
      color: #133351; }
  .pagination .swiper-button.prev {
    transform: scaleX(-1); }

.sorting {
  display: flex;
  align-items: center; }
  .sorting p {
    margin-right: 10px;
    color: #BDBDBD;
    font-size: .9em; }
  .sorting .nice-select {
    margin-left: 10px;
    font-size: 12px;
    min-width: 166px;
    border-color: #BDBDBD; }
    .sorting .nice-select .list {
      left: auto;
      right: 0;
      border-color: #BDBDBD; }

@media (max-width: 1023px) {
  .catalog {
    margin-right: -10px;
    margin-left: -10px; }
  .product-preview {
    padding: 20px;
    width: calc(33.3% - 20px);
    margin: 0 10px 20px 10px; } }

@media (max-width: 735px) {
  .catalog {
    margin-right: -5px;
    margin-left: -5px; }
    .catalog-head {
      margin-bottom: 25px;
      align-items: center; }
      .catalog-head h2 {
        margin-bottom: 0; }
  .product-preview {
    width: calc(50% - 10px);
    margin: 0 5px 10px 5px;
    padding: 15px 10px 20px 10px; }
    .product-preview__img {
      height: 70px;
      margin-bottom: 10px; }
    .product-preview__title {
      margin-bottom: 5px; }
    .product-preview__price--old {
      color: #959595;
      font-size: 10px;
      margin-bottom: 3px; }
    .product-preview__price--new {
      font-size: 14px;
      margin-bottom: 10px; }
  .sorting p {
    display: none; }
  .sorting .nice-select {
    font-size: 10px;
    white-space: unset;
    line-height: initial;
    display: flex;
    align-items: center; }
    .sorting .nice-select .option {
      line-height: 30px;
      min-height: 30px; }
  .pagination {
    margin: 30px 0; } }

.slider-products {
  margin: -15px; }
  .slider-products .swiper-container {
    padding: 15px; }
    .slider-products .swiper-container .swiper-slide {
      height: auto; }
  .slider-products .product-preview {
    height: 100%;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1); }
  .slider-products .slider-nav {
    top: -72px; }
  .slider-products .swiper-pagination {
    position: relative;
    bottom: auto;
    margin-top: 20px;
    padding-bottom: 20px; }
  .slider-products--combo .product-preview {
    height: calc(50% - 16px);
    margin-bottom: 35px; }
    .slider-products--combo .product-preview:nth-child(2) {
      margin-bottom: 0; }

@media (max-width: 1023px) {
  .slider-products--combo .product-preview {
    height: calc(50% - 10px);
    margin-bottom: 20px; }
    .slider-products--combo .product-preview:nth-child(2) {
      margin-bottom: 0; } }

@media (max-width: 735px) {
  .slider-products--combo .product-preview {
    height: calc(50% - 5px);
    margin-bottom: 5px; }
    .slider-products--combo .product-preview:nth-child(2) {
      margin-bottom: 0; } }

.advantages {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  .advantages-item {
    text-align: center;
    width: 33.3%;
    font-size: 1.1em;
    font-weight: 500; }
    .advantages-item__icon {
      width: 100px;
      height: 100px;
      border-radius: 4px;
      border: 1px solid #BE2E2E;
      margin: 0 auto 25px auto;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transition: .5s; }
      .advantages-item__icon img {
        max-height: 100%;
        transition: .5s; }
    .advantages-item:hover .advantages-item__icon {
      border-color: #133351; }
      .advantages-item:hover .advantages-item__icon img {
        transform: scaleX(-1) translateY(-10px); }

.about {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start; }
  .about-text {
    padding-top: 30px;
    flex: 1;
    max-width: 460px;
    margin-bottom: 30px; }
  .about-img {
    float: right;
    background: #fff;
    border-radius: 4px;
    padding: 10px;
    text-align: center;
    width: 420px;
    max-width: 33%;
    margin-top: -50px;
    margin-bottom: 30px;
    box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.15); }

@media (max-width: 735px) {
  .about {
    display: block; }
    .about-text {
      max-width: 100%;
      width: 100%; }
    .about-img {
      margin: 0 auto;
      float: none;
      max-width: 90%;
      width: 270px; }
  .advantages {
    justify-content: flex-start; }
    .advantages-item {
      font-size: .9em;
      text-align: left;
      width: 50%;
      margin-bottom: 20px; }
      .advantages-item__icon {
        width: 50px;
        height: 50px;
        margin-bottom: 10px;
        margin-right: 0;
        margin-left: 0; } }

.delivery {
  margin: 45px 0; }
  .delivery .text {
    width: 460px;
    max-width: 100%;
    padding-bottom: 200px; }
  .delivery-img {
    position: absolute;
    bottom: -45px;
    left: 400px;
    max-height: 100%; }

@media (max-width: 700px) {
  .delivery-img {
    left: 0;
    max-width: 200%;
    max-height: 50%; } }

.basket {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: flex-start; }
  .basket-info {
    width: 360px;
    border-radius: 4px;
    background-color: #FAFAFA;
    padding: 35px; }
    .basket-info table {
      width: 100%; }
      .basket-info table b {
        font-weight: 900; }
      .basket-info table td, .basket-info table th {
        padding: 15px 5px;
        text-align: left; }
        .basket-info table td:last-of-type, .basket-info table th:last-of-type {
          text-align: right; }
      .basket-info table tfoot {
        border-top: 1px dashed #BDBDBD; }
      .basket-info table th {
        font-size: 1.1em;
        font-weight: 900; }
    .basket-info__sum {
      font-size: 24px; }
  .basket-table {
    width: calc(100% - 400px); }
    .basket-table table {
      width: 100%; }
      .basket-table table td, .basket-table table th {
        padding: 15px 5px;
        text-align: left; }
        .basket-table table td:first-child, .basket-table table th:first-child {
          padding-left: 0; }
      .basket-table table thead {
        border-bottom: 1px solid #BDBDBD; }
      .basket-table table th {
        color: #BDBDBD;
        font-weight: 500;
        font-size: .8em; }
  .basket .options__product__price {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    padding: 0;
    white-space: nowrap; }
  .basket .options__product {
    margin: 10px 0; }
    .basket .options__product--img {
      width: 60px;
      height: 50px; }
    .basket .options__product--title {
      width: calc(100% - 60px);
      max-width: 200px;
      font-size: .9em; }
  .basket .cart-options__delete {
    position: static;
    top: auto;
    left: auto;
    transform: none;
    margin: 5px 0 0 0; }

.number {
  display: flex;
  align-items: center; }
  .number span {
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: .5s; }
    .number span.minus {
      background-image: url(../img/icons/minus.svg); }
    .number span.plus {
      background-image: url(../img/icons/plus.svg); }
    .number span:hover {
      opacity: .5; }
  .number input {
    width: 50px;
    text-align: center;
    font-size: 20px;
    font-weight: 700; }

.order {
  padding-top: 80px; }
  .order-row {
    width: 100%;
    margin: 0;
    margin-top: -1px;
    display: flex;
    align-items: center;
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    padding: 25px 0;
    flex-wrap: wrap; }
    .order-row > * {
      margin: 10px 30px 10px 0; }
      .order-row > *:last-child {
        margin-right: 0; }
    .order-row .form-field {
      font-weight: 600;
      max-width: calc(33.3% - 20px);
      flex: 1; }
      .order-row .form-field input {
        font-weight: 400; }
      .order-row .form-field input, .order-row .form-field .nice-select {
        margin-bottom: 0; }
      .order-row .form-field .nice-select {
        border-radius: 30px; }
      .order-row .form-field--address {
        max-width: calc(100% - 360px - 30px); }
      .order-row .form-field--delivery {
        flex: 1;
        max-width: 360px;
        margin-right: 0; }
    .order-row .order-row {
      padding: 0;
      border: none;
      margin: 0; }
      .order-row .order-row + .order-row {
        padding-top: 20px; }
    .order-row:last-of-type {
      border: none; }
  .order .btn {
    width: auto;
    min-width: 220px; }

@media (max-width: 1199px) {
  .basket-info {
    padding: 20px;
    width: 280px; }
  .basket-table {
    width: calc(100% - 310px); } }

@media (max-width: 1023px) {
  .basket {
    display: block; }
    .basket-info {
      padding: 20px;
      width: 100%; }
    .basket-table {
      width: 100%;
      margin-bottom: 30px; }
  .order {
    padding-top: 30px; }
  .form_radio label {
    padding-top: 3px; }
  .order-row .form-field {
    max-width: 100%;
    flex: auto;
    width: 100%;
    margin-right: 0; }
    .order-row .form-field--delivery {
      flex: none;
      max-width: 100%;
      margin-right: 0; }
    .order-row .form-field--address {
      max-width: 100%;
      flex: none; } }

@media (max-width: 735px) {
  .basket-table table {
    display: block; }
    .basket-table table thead {
      display: none; }
    .basket-table table tbody {
      display: block; }
    .basket-table table tr {
      padding: 10px 0;
      border-bottom: 1px solid #959595;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between; }
      .basket-table table tr:last-child {
        border: none; }
      .basket-table table tr td {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 10px;
        max-width: 33.3%; }
        .basket-table table tr td:first-child {
          width: 100%;
          max-width: 100%;
          padding-right: 0; }
        .basket-table table tr td .options-price--mob-hidden {
          display: none; }
  .order > .order-row:last-child {
    justify-content: center;
    display: flex;
    flex-direction: column; }
  .order > .order-row .consent {
    width: 200px; }
  .order > .order-row > * {
    margin-left: 15px;
    margin-right: 15px; } }

.characteristics {
  border-radius: 4px;
  background: #FAFAFA;
  padding: 40px 60px; }
  .characteristics table {
    width: 100%; }
    .characteristics table tr {
      border-bottom: 1px dashed #959595; }
      .characteristics table tr:last-child {
        border-bottom: none; }
    .characteristics table td {
      padding: 10px 5px;
      font-size: .9em; }
      .characteristics table td:last-child {
        font-weight: 900;
        font-size: 1em;
        text-align: right; }

.reviews-item {
  margin-bottom: 30px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.15);
  padding: 30px; }
  .reviews-item .text {
    margin-bottom: 0; }

.reviews-top {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .reviews-top > * {
    margin-bottom: 20px;
    margin-right: 20px; }
    .reviews-top > *:last-child {
      margin-right: 0; }

.reviews-author {
  display: flex;
  align-items: center; }
  .reviews-author__photo {
    border-radius: 100%;
    width: 50px;
    height: 50px;
    background: url(../img/icons/author.svg) no-repeat center #BDBDBD;
    background-size: 50%;
    overflow: hidden; }
    .reviews-author__photo img {
      width: 100%;
      min-height: 100%;
      object-fit: cover;
      object-position: center;
      transition: .5s; }
  .reviews-author p {
    font-weight: bold;
    font-size: 1.1em;
    width: calc(100% - 50px);
    padding-left: 15px; }

.reviews .date {
  padding: 0; }
  .reviews .date:before {
    display: none; }

.product {
  padding: 50px 0 80px 0;
  display: flex;
  justify-content: space-between; }
  .product-slider, .product-text {
    width: calc(50% - 35px); }
  .product .availability {
    font-weight: 500;
    font-size: .9em;
    margin-bottom: 20px;
    padding-left: 12px; }
    .product .availability:before {
      content: '';
      position: absolute;
      top: 5px;
      left: 0;
      width: 4px;
      height: 4px;
      border-radius: 4px; }
    .product .availability-true {
      color: #27AE60; }
      .product .availability-true:before {
        background: #27AE60; }
    .product .availability-false {
      color: #EB5757; }
      .product .availability-false:before {
        background: #EB5757; }
  .product .number {
    border: 1px solid #BDBDBD;
    padding: 5px 10px;
    border-radius: 30px;
    margin-bottom: 0;
    display: inline-flex; }
  .product-text--row {
    display: flex;
    align-items: center;
    margin: 0 0 15px 0; }
    .product-text--row > * {
      margin: 0 35px 10px 0; }
    .product-text--row:last-child {
      margin-right: 0; }
  .product .product-preview__price {
    margin-top: 0;
    padding: 0; }
    .product .product-preview__price--mob {
      display: none; }
    .product .product-preview__price--old {
      font-size: .9em;
      color: #BDBDBD; }
    .product .product-preview__price--new {
      font-size: 24px; }
  .product-slider {
    height: 380px;
    padding: 35px 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between; }
    .product-slider .swiper-container {
      height: 300px;
      margin: 0; }
    .product-slider .gallery-top {
      width: calc(100% - 100px);
      height: calc(100% + 70px);
      margin-top: -35px; }
    .product-slider .gallery-thumbs {
      width: 70px; }
      .product-slider .gallery-thumbs .swiper-slide {
        border-radius: 4px;
        opacity: .3;
        cursor: pointer;
        transition: .5s;
        border: 1px solid #BE2E2E; }
        .product-slider .gallery-thumbs .swiper-slide-thumb-active {
          opacity: 1; }
    .product-slider .swiper-slide {
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column; }
      .product-slider .swiper-slide img {
        max-height: 100%; }
    .product-slider .swiper-button {
      margin: 0;
      left: 20px;
      right: auto;
      background-color: transparent; }
      .product-slider .swiper-button.swiper-button-next {
        bottom: 0;
        top: auto;
        transform: rotate(90deg); }
      .product-slider .swiper-button.swiper-button-prev {
        top: 0;
        bottom: auto;
        transform: rotate(-90deg); }

@media (max-width: 1199px) {
  .product {
    padding: 20px 0 35px 0; } }

@media (max-width: 735px) {
  .product {
    padding: 0 0 10px 0;
    display: block; }
    .product-slider, .product-text {
      width: 100%; }
    .product-slider {
      margin-bottom: 25px;
      height: 260px; }
      .product-slider .swiper-container {
        height: 180px; }
    .product .gallery-thumbs {
      width: 50px; }
    .product .gallery-top {
      width: calc(100% - 70px);
      height: calc(100% + 70px);
      margin-top: -35px; }
    .product .swiper-button {
      left: 10px; }
    .product .product-preview__price--desktop {
      display: none; }
    .product .product-preview__price--mob {
      display: block;
      margin: 0; }
    .product .number {
      margin-bottom: 0; }
    .product .btn {
      padding-top: 20px;
      padding-bottom: 20px;
      width: 280px; }
  .characteristics {
    padding: 20px; }
  .reviews-item {
    padding: 20px;
    margin-bottom: 15px; }
  .reviews-author__photo {
    width: 26px;
    height: 26px; }
  .reviews-author p {
    width: calc(100% - 26px); } }

.user-menu {
  position: absolute;
  padding: 10px 20px 20px 20px;
  transition: .5s;
  top: 100%;
  right: 0;
  width: 200px;
  background-color: #fff;
  border-radius: 4px;
  text-align: right;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  transform: translateY(50px);
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.15);
  overflow: auto;
  max-height: 60vh; }
  .user-menu ul {
    list-style: none; }
    .user-menu ul li {
      font-size: 14px;
      margin-top: 4px; }
  .user-menu.active {
    opacity: 1;
    visibility: visible;
    z-index: 3;
    transform: translateY(5px); }
