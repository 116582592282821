
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// Определение своих значений
@import "variables";

// Подключение нужных SCSS исходников
@import '../../../node_modules/swiper/css/swiper.min.css';
@import '../../../node_modules/normalize.css/normalize.css';
@import '../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.min.css';
@import '../../../node_modules/jquery-nice-select/css/nice-select.css';

//system
@import "system/mixin";
@import "system/settings";
@import "system/form";
@import "system/modal";

//section
@import "section/header";
@import "section/footer";
@import "section/slider-home";
@import "section/map";
@import "section/news";
@import "section/slider-news";
@import "section/subscription";
@import "section/category";
@import "section/catalog";
@import "section/slider-products";
@import "section/about";
@import "section/delivery";
@import "section/basket";
@import "section/product";

// Подключение своих SCSS файлов
@import "my";
