
//placeholder

input::-webkit-input-placeholder {
  color: $gray-1;
}
input::-moz-placeholder {
  color: $gray-1;
}
input:-moz-placeholder {
  color: $gray-1;
}
input:-ms-input-placeholder {
  color: $gray-1;
}
textarea::-webkit-input-placeholder {
  color: $gray-1;
}
textarea::-moz-placeholder {
  color: $gray-1;
}
textarea:-moz-placeholder {
  color: $gray-1;
}
textarea:-ms-input-placeholder {
  color: $gray-1;
}

input:focus::-webkit-input-placeholder{color:transparent;transition:all .5s ease}input:focus::-moz-placeholder{color:transparent;transition:all .5s ease}input:focus:-moz-placeholder{color:transparent;transition:all .5s ease}input:focus:-ms-input-placeholder{color:transparent;transition:all .5s ease}textarea:focus::-webkit-input-placeholder{color:transparent;-webkit-transition:all .5s ease;-o-transition:all .5s ease;-moz-transition:all .5s ease;transition:all .5s ease}textarea:focus::-moz-placeholder{color:transparent;-webkit-transition:all .5s ease;-o-transition:all .5s ease;-moz-transition:all .5s ease;transition:all .5s ease}textarea:focus:-moz-placeholder{color:transparent;-webkit-transition:all .5s ease;-o-transition:all .5s ease;-moz-transition:all .5s ease;transition:all .5s ease}textarea:focus:-ms-input-placeholder{color:transparent;-webkit-transition:all .5s ease;-o-transition:all .5s ease;-moz-transition:all .5s ease;transition:all .5s ease}

//btn

.btn {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
  padding: 13px 19px;
  border: 1px solid #828282;
  border-radius: 30px;
  &-indent {
    margin: $indent-big;
    min-width: 150px;
  }
  &-arrow {
    span {
      padding-right: 30px;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        background-image: url(../img/icons/arrow-long.svg);
        background-repeat: no-repeat;
        background-position: center;
        width: 17px;
        height: 10px;
        margin-top: -5px;
        transition: .5s;
      }
    }
    &:hover {
      span {
        &:before {
          margin-right: -5px;
          background-image: url(../img/icons/arrow-long--white.svg);
        }
      }
    }
  }
  &-basket {
    white-space: nowrap;
    span {
      padding-left: 22px;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        width: 12px;
        height: 12px;
        background-size: 12px;
        margin-top: -8px;
        left: 0;
        transition: .5s;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(../img/icons/shopping-cart-red.svg);
      }
    }
    &:hover {
      span {
        &:before {
          background-image: url(../img/icons/shopping-cart-white.svg);
        }
      }
    }
  }

  //hover
  &:hover {
    background: $red-2;
    border-color: $red-2;
    color: $white;
  }

  &-blue {
    font-size: 12px;
    padding-top: 20px;
    padding-bottom: 20px;
    border: none;
    color: $white;
    background: $blue-2;
    &:hover {
      background: $blue-1;
    }
  }
}

.form-field {
  font-weight: 500;
  font-size: 12px;
  display: block;
  input {
    font-weight: 500;
    font-size: 12px;
    border-radius: 50px;
    border: 1px solid $gray-1;
    color: $black;
    padding: 13px 20px;
    &:focus {
      border-color: $blue-3;
    }
  }
  input, .nice-select {
    margin: 10px 0 25px 0;
    display: block;
    width: 100%;
  }
}

.consent {
  font-size: 12px;
  label {
    display: block;
    padding-left: 28px;
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 14px;
      height: 14px;
      cursor: pointer;
      z-index: 1;
    }
  }
  &-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 14px;
    height: 14px;
    border: 1px solid $gray-1;
    transition: .5s;
  }
  input:checked + .consent-icon {
    background: $blue-2;
    border-color: $blue-2;
  }
  input:hover + .consent-icon {
    border-color: $blue-2;
  }
}

.form_radio {
  input[type=radio] {
    display: none;
  }
  label {
    display: inline-block;
    cursor: pointer;
    padding-left: 30px;
    user-select: none;
    &:before,
    &:after {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      position: absolute;
      left: 0;
      top: -1px;
      border-radius: 20px;
      border: 1px solid $gray-1;
      transition: .5s;
    }
    &:after {
      width: 14px;
      height: 14px;
      top: 2px;
      left: 3px;
      background: $red-2;
      opacity: 0;
    }
  }
  input[type=radio]:checked + label:before,
  input[type=radio]:checked + label:after {
    opacity: 1;
    border-color: $red-2;
  }
}

.nice-select {
  border-color: $gray-1;
  .list {
    width: 100%;
  }
}

