
.wrap-map {
  padding: 100px 0;
  margin-bottom: -25px;
  iframe {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
  .container {
    pointer-events: none;
  }
}

.map-contacts {
  width: 450px;
  max-width: calc(100% - 40px);
  margin: 100px 20px;
  background: $white;
  border-radius: $radius-1;
  @include shadow;
  padding: 10px 50px;
  pointer-events: auto;
  &__item {
    margin: 45px 0;
    padding-left: 45px;
    font-weight: 500;
    img {
      position: absolute;
      top: 50%;
      left: 0;
      max-width: 25px;
      transform: translateY(-50%);
    }
  }
}

@media (max-width: 1023px) {

  .wrap-map {
    padding: 50px 0;
  }
  .map-contacts {
    margin: 0;    max-width: 100%;
    &__item {
      margin: 20px 0;
    }
  }

}
@media (max-width: 735px) {

  .wrap-map {
    padding: 200px 0 20px 0;
  }
  .map-contacts {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    &__item {
      padding-left: 30px;
    }
  }

}