
.slider-products {
  margin: -15px;
  .swiper-container {
    padding: 15px;
    .swiper-slide {
      height: auto;
    }
  }
  .product-preview {
    height: 100%;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    box-shadow: 0px 5px 10px rgba($black,.1);
  }
  .slider-nav {
    top: -72px;
  }
  .swiper-pagination {
    position: relative;
    bottom: auto;
    margin-top: 20px;
   padding-bottom: 20px;
  }

  &--combo {
    .product-preview {
      height: calc(50% - 16px);
      margin-bottom: 35px;
      &:nth-child(2) {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 1023px) {
  .slider-products {
    &--combo {
      .product-preview {
        height: calc(50% - 10px);
        margin-bottom: 20px;
        &:nth-child(2) {
          margin-bottom: 0;
        }
      }
    }
  }
}
@media (max-width: 735px) {

  .slider-products {
    &--combo {
      .product-preview {
        height: calc(50% - 5px);
        margin-bottom: 5px;
        &:nth-child(2) {
          margin-bottom: 0;
        }
      }
    }
  }


}


