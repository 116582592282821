
.subscription {
  background: #f9f9f9;
  &-bg {
    position: absolute;
    right: 50%;
    top: 0;
    height: 100%;
    width: 100%;
    max-width: 620px;
    img {
      @include image-size;
    }
  }
  &-content {
    width: 50%;
    float: right;
    padding: 70px;
    &__block {
      width: 250px;
      margin: 0 auto;
    }
  }
  &-form {
    .subscription-email {
      width: 100%;
      display: block;
      margin-bottom: 35px;
      border-bottom: 1px solid $black;
      padding: 10px 0 10px 35px;
      background: url(../img/icons/mail-gray.svg) no-repeat left center;
    }
  }
}

@media (max-width: 735px) {

  .subscription {
    &-bg {
      display: none;
    }
    &-content {
      width: 100%;
      float: none;
      padding: 30px 0;
      text-align: center;
      justify-content: center;
    }
  }

}
