
.characteristics {
  border-radius: $radius-1;
  background: $gray-0;
  padding: 40px 60px;
  table {
    width: 100%;
    tr {
      border-bottom: 1px dashed $gray-2;
      &:last-child {
        border-bottom: none;
      }
    }
    td {
      padding: 10px 5px;
      font-size: .9em;
      &:last-child {
        font-weight: 900;
        font-size: 1em;
        text-align: right;
      }
    }
  }
}

.reviews {

  &-item {
    margin-bottom: 30px;
    background: $white;
    border-radius: $radius-2;
    @include shadow;
    padding: 30px;
    .text {
      margin-bottom: 0;
    }
  }
  &-top {
    @include horizonCenterBetween;
    & > * {
      margin-bottom: 20px;
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &-author {
    @include horizonCenter;
    &__photo {
      border-radius: 100%;
      width: 50px;
      height: 50px;
      background: url(../img/icons/author.svg) no-repeat center $gray-1;
      background-size: 50%;
      overflow: hidden;
      img {
        @include image-size;
      }
    }
    p {
      font-weight: bold;
      font-size: 1.1em;
      width: calc(100% - 50px);
      padding-left: 15px;
    }
  }
  .date {
    padding: 0;
    &:before {
      display: none;
    }
  }
}

.product {
  padding: 50px 0 80px 0;
  display: flex;
  justify-content: space-between;
  &-slider,
  &-text {
    width: calc(50% - 35px);
  }
  .vendor-code {

  }
  .availability {
    font-weight: 500;
    font-size: .9em;
    margin-bottom: 20px;
    padding-left: 12px;
    &:before {
      content: '';
      position: absolute;
      top: 5px;
      left: 0;
      width: 4px;
      height: 4px;
      border-radius: 4px;
    }
    &-true {
      color: $green;
      &:before {
        background: $green;;
      }
    }
    &-false {
      color: $red-1;
      &:before {
        background: $red-1;;
      }
    }
  }
  .number {
    border: 1px solid $gray-1;
    padding: 5px 10px;
    border-radius: 30px;
    margin-bottom: 0;
    display: inline-flex;
  }
  &-text--row {
    @include horizonCenter;
    margin: 0 0 15px 0;
    & > * {
      margin: 0 35px 10px 0;
    }
    &:last-child {
      margin-right: 0;
    }

  }
  .product-preview__price {
    margin-top: 0;
    padding: 0;
    &--mob {
      display: none;
    }
    &--old {
      font-size: .9em;
      color: $gray-1;
    }
    &--new {
      font-size: 24px;
    }
  }

  &-slider {
    height: 380px;
    padding: 35px 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    .swiper-container {
      height: 300px;
      margin: 0;
    }
    .gallery-top {
      width: calc(100% - 100px);
      height: calc(100% + 70px);
      margin-top: -35px;
    }
    .gallery-thumbs {
      width: 70px;
      .swiper-slide {
        border-radius: 4px;
        opacity: .3;
        cursor: pointer;
        transition: .5s;
        border: 1px solid $red-2;
        &-thumb-active {
          opacity: 1;
        }
      }
    }
    .swiper-slide {
      padding: 10px;
      @include verticalCenter;
      img {
        max-height: 100%;
      }
    }
    .swiper-button {
      margin: 0;
      left: 20px;
      right: auto;
      background-color: transparent;
      &.swiper-button-next {
        bottom: 0;
        top: auto;
        transform: rotate(90deg);
      }
      &.swiper-button-prev {
        top: 0;
        bottom: auto;
        transform: rotate(-90deg);
      }
    }
  }

}

@media (max-width: 1199px) {

  .product {
    padding: 20px 0 35px 0;
  }

}

@media (max-width: 735px) {

  .product {
    padding: 0 0 10px 0;
    display: block;
    &-slider,
    &-text {
      width: 100%;
    }
    &-slider {
      margin-bottom: 25px;
      height: 260px;
      .swiper-container {
        height: 180px;
      }
    }
    .gallery-thumbs {
      width: 50px;
    }
    .gallery-top {
      width: calc(100% - 70px);
      height: calc(100% + 70px);
      margin-top: -35px;
    }
    .swiper-button {
      left: 10px;
    }
    .product-preview__price--desktop {
      display: none;
    }
    .product-preview__price--mob {
      display: block;
      margin: 0;
    }
    .number {
      margin-bottom: 0;
    }
    .btn {
      padding-top: 20px;
      padding-bottom: 20px;
      width: 280px;
    }
  }
  .characteristics {
    padding: 20px;
  }
  .reviews-item {
    padding: 20px;
    margin-bottom: 15px;
  }
  .reviews-author {
    &__photo {
      width: 26px;
      height: 26px;
    }
    p {
      width: calc(100% - 26px);
    }
  }

}
