
//normalize

*{position:relative;margin:0;padding:0;box-sizing:border-box}a,button,span{display:inline-block}input,textarea{display:block;width:100%}table{width:100%;border-collapse:collapse}a{background-color:transparent;text-decoration:none}img{border:none;max-width:100%;height:auto}a,button{outline:0;cursor:pointer}li{list-style:none}button{background:0 0;border:none}a,button,input,textarea{font-weight:inherit;-webkit-appearance:none;transition:all .5s ease}:after,:before{box-sizing:border-box}textarea{resize:none;outline:0;border:none;-webkit-appearance:none;border-radius:0;background:0 0}input{outline:0;border:none;-webkit-appearance:none;border-radius:0;background:0 0}input[type=number]{-moz-appearance:textfield}input::-webkit-inner-spin-button,input::-webkit-outer-spin-button{-webkit-appearance:none}input[type=submit]{cursor:pointer;-webkit-appearance:none;display:inline-block}input[type=search]::-webkit-search-cancel-button{background:0 0;display:none}.text-align-left{text-align:left}.text-align-center{text-align:center}.text-align-right{text-align:right}.full-img img{width:100%}figure{text-align:center;margin-bottom:30px;margin-top:5px;font-size:.75em;opacity:.8}[hidden]{display:none!important}.img-full{width: 100%;}.clearfix{&:after{content: '';clear: both;display: block}}

//fonts

@font-face {
  font-family: 'Roboto', sans-serif;
}

//list-page

.list-page {
  a {
    display: block;
    margin: 5px 0;
  }
}

//title

h1, .h1 {
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 50px;
}
h2, .h2 {
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 50px;
  @media (max-width: 1023px) {
    font-size: 22px;
  }
  @media (max-width: 735px) {
    font-size: 18px;
    margin-bottom: 25px;
  }
}
h3, .h3 {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 30px;
}
h4, .h4 {
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 20px;
}
h5, .h5 {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px;
}
h6, .h6 {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 20px;
}

.subtitle {
  display: block;
  font-size: 14px;
  padding-top: 10px;
  font-weight: 400;
}

.title {

  &-combo-nav {
    padding-right: 100px;
  }
}

//color btn

a,button,input,textarea{color:$black;}

.link {
  color: $blue-1;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

//text

.text {
  margin-bottom: 30px;
  & > * + * {
    margin-top: 15px;
  }
}

//body

body {
  font-family: $font;
  color: $black;
  font-style: normal;
  font-weight: normal;
  background: $white;
  font-size: 16px;
  line-height: 1.2;
  @media (max-width: 1023px) {
    font-size: 14px;
  }
}

.wrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  width: 100%;
}
main {
  padding: 25px 0;
  flex: auto;
  display: block;
}
footer {
  margin: auto auto 0 auto;
  width: 100%;
}

.container,
.container-fluid {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  z-index: 2;
}

.container {
  max-width: 1200px;
}
.container-fluid {
  max-width: 1920px;
}


//btn

.btn-row {
  text-align: center;
  .btn {
    margin: 10px;
  }
}


//row

.column {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -20px;
  .column-2 {
    width: 50%;
    padding: 10px 20px;
  }
  .column-3 {
    max-width: 320px;
    width: 33.3%;
    padding: 20px;
  }
}

//fancybox

.fancybox-navigation {
  position: static;
}

//swiper

.swiper-button {
  outline: none;
  border-radius: 100%;
  background-repeat: no-repeat;
  background-color: #D4DCE5;
  background-position: center;
  background-image: url(../img/icons/arrow-next--black.svg);
  background-size: 10px;
  width: 30px;
  height: 30px;
  transition: .5s;
  &:hover {
    background-color: $gray-1;
  }
  &.swiper-button-prev {
    transform: scaleX(-1);
  }
  &:before, &:after {
    display: none;
  }
}

.swiper-pagination {
  position: absolute;
  width: 100%;
  text-align: center;
  margin-top: 50px;
  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    opacity: 1;
    margin: 0 8px;
    outline: none;
    border: 1px solid #828282;
    background: transparent;
    &.swiper-pagination-bullet-active {
      background-color: $red-2;
    }
  }
}

//breadcrumbs

.breadcrumbs {
  padding-top: 20px;
  margin-bottom: 45px;
  font-size: 12px;
  font-weight: 500;
  span {
    display: inline-block;
    vertical-align: top;
    padding-right: 20px;
    margin-right: 10px;
    &:last-of-type {
      padding-right: 0;
      margin-right: 0;
      &:after {
        display: none;
      }
    }
    &:after {
      content: '/';
      position: absolute;
      top: 0;
      right: 0;
      color: $gray-1;
    }
    a {
      color: $gray-1;
      &:hover {
        color: $red-2;
      }
    }
  }
  @media (max-width: 735px) {
    margin-bottom: 30px;
  }
}

//block

.block {
  margin: $indent-big;
  @media (max-width:735px) {
    margin: 35px 0;
  }
}

.slider-nav {
  position: absolute;
  top: 5px;
  right: 0;
  display: flex;
  .swiper-button {
    margin: 0 10px;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
  }
  @media (max-width:735px) {
      top: -7px;
  }
}

//tabs

.tabs_content {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  pointer-events: none;
  &.active {
    opacity: 1;
    position: relative;
    z-index: 1;
    pointer-events: auto;
  }
}

.wrap_tabs__products {
  padding-bottom: 35px;
  h2 {
    font-size: 20px;
  }
  .tabs_caption {
    border-bottom: 1px solid $gray-1;
  }
  .tabs_content {
    right: auto;
    width: 560px;
    max-width: 100%;
  }
}

.tabs_caption {
  padding-right: 100px;
  margin-bottom: 45px;
  display: flex;
  li {
    margin-bottom: 0;
    margin-right: 35px;
    cursor: pointer;
    transition: .5s;
    &:last-child {
      margin-right: 0;
    }
    h2 {
      margin-bottom: 0;
      color: $gray-1;
      transition: .5s;
      padding-bottom: 10px;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        background: $red-2;
        height: 4px;
        transition: .5s;
      }
    }
    .slider-nav {
      opacity: 0;
      pointer-events: none;
    }

    //hover
    &:hover {
      h2 {
        color: $gray-2;
      }
    }

    //active
    &.active {
      color: $red-2;
      h2 {
        color: $red-2;
        &:after {
          width: 100%;
          right: auto;
          left: 0;
        }
      }
      .slider-nav {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}
@media (max-width: 735px) {

  .slider-products {
    &--combo {
      .product-preview {
        height: calc(50% - 5px);
        margin-bottom: 5px;
        &:nth-child(2) {
          margin-bottom: 0;
        }
      }
    }
  }
  .wrap_tabs {
    .slider-nav {
      display: none;
    }
    .tabs_caption {
      padding-right: 0;
      margin-bottom: 25px;
      li {
        margin-right: 10px;
        @include verticalCenter;
        &:last-child {
          margin-right: 0;
        }
        h2 {
          height: 100%;
          @include verticalCenter;
          text-align: center;
          font-size: 14px;
          padding-bottom: 5px;
          &:after {
            height: 1px;
          }
        }
      }
    }
  }

}

//white-space

.white-space {
  &--nowrap {
    white-space: nowrap;
  }
}