
.modal, .modal-content > * {
  transform: translateY(150px);
  opacity: 0;
  -webkit-transition: opacity .5s, -webkit-transform .5s;
  transition: opacity .5s, -webkit-transform .5s;
  -o-transition: opacity .5s, -o-transform .5s;
  -moz-transition: transform .5s, opacity .5s, -moz-transform .5s;
  transition: transform .5s, opacity .5s;
  transition: transform .5s, opacity .5s, -webkit-transform .5s, -moz-transform .5s, -o-transform .5s;
}

.fancybox-slide--current .modal, .fancybox-slide--current .modal-content > * {
  opacity: 1;
  transform: translateY(0);
}

.modal{
  display: none;
  max-width: 100%;
  border-radius: $radius-2;
  background-color: $white;
  overflow: visible;
  padding: 60px 20px;
  width: 440px;
  @include shadow;
  &-content {
    width: 260px;
    max-width: 100%;
    margin: 0 auto;
  }
  h3 {
    margin-bottom: 20px;
  }
  &-movement {
    margin-bottom: 40px;
    color: $gray-1;
    font-size: .9em;
  }
  .btn {
    width: 100%;
  }
  .consent {
    width: 200px;
    margin: 25px auto 0 auto;
    max-width: 100%;
  }
}

.forgot-password {
  text-align: center;
  margin-top: 25px;
  font-size: 12px;
}

