
.catalog {
  margin-right: -20px;
  margin-left: -20px;
  display: flex;
  flex-wrap: wrap;

  //head
  &-head {
    @include horizonCenterBetween;
    align-items: flex-start;
    h2 {
      margin-right: 10px;
    }
  }
}

.product-preview {
  width: calc(25% - 40px);
  margin: 0 20px 40px 20px;
  background: $white;
  border-radius: $radius-1;
  @include shadow;
  padding: 30px;
  &__img {
    height: 140px;
    margin-bottom: 15px;
    width: 100%;
    @include verticalCenter;
    img {
      max-height: 100%;
    }
  }
  &__title {
    font-size: .9em;
    margin-bottom: 15px;
    &:hover {
      color: $red-2;
    }
  }
  &__price {
    &--old {
      font-weight: 500;
      font-size: 12px;
      margin-bottom: 6px;
      text-decoration: line-through;
      white-space: nowrap;
    }
    &--new {
      color: $red-1;
      font-weight: 900;
      font-size: 16px;
      margin-bottom: 20px;
      white-space: nowrap;
    }
  }
}

.vendor-code {
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 3px;
  color: $gray-1;
}

.pagination {
  font-weight: 500;
  margin: $indent-big;
  display: flex;
  align-items: center;
  justify-content: center;
  & > * {
    margin: 0 10px;
  }
  a {
    color: $gray-1;
    &:hover {
      color: $blue-3;
    }
  }
  .swiper-button {
    &.prev {
      transform: scaleX(-1);
    }
  }
}

.sorting {
  @include horizonCenter;
  p {
    margin-right: 10px;
    color: $gray-1;
    font-size: .9em;
  }
  .nice-select {
    margin-left: 10px;
    font-size: 12px;
    min-width: 166px;
    border-color: $gray-1;
    .list {
      left: auto;
      right: 0;
      border-color: $gray-1;
    }
  }
}

@media (max-width: 1023px) {

  .catalog {
    margin-right: -10px;
    margin-left: -10px;
  }
  .product-preview {
    padding: 20px;
    width: calc(33.3% - 20px);
    margin: 0 10px 20px 10px;
  }

}
@media (max-width: 735px) {

  .catalog {
    margin-right: -5px;
    margin-left: -5px;
    &-head {
      margin-bottom: 25px;
      align-items: center;
      h2 {
        margin-bottom: 0;
      }
    }
  }
  .product-preview {
    width: calc(50% - 10px);
    margin: 0 5px 10px 5px;
    padding: 15px 10px 20px 10px;
    &__img {
      height: 70px;
      margin-bottom: 10px;
    }
    &__title {
      margin-bottom: 5px;
    }
    &__price {
      &--old {
        color: $gray-2;
        font-size: 10px;
        margin-bottom: 3px;
      }
      &--new {
        font-size: 14px;
        margin-bottom: 10px;
      }
    }
  }
  .sorting {
    p {
      display: none;
    }
    .nice-select {
      font-size: 10px;
      white-space: unset;
      line-height: initial;
      display: flex;
      align-items: center;
      .option {
        line-height: 30px;
        min-height: 30px;
      }
    }
  }
  .pagination {
    margin: 30px 0;
  }

}