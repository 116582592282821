
.basket {
  @include horizonCenterBetween;
  align-items: flex-start;
  &-info {
    width: 360px;
    border-radius: $radius-1;
    background-color: $gray-0;
    padding: 35px;
    table {
      width: 100%;
      b {
        font-weight: 900;
      }
      td, th {
        padding: 15px 5px;
        text-align: left;
        &:last-of-type {
          text-align: right;
        }
      }
      tfoot {
        border-top: 1px dashed $gray-1;
      }
      th {
        font-size: 1.1em;
        font-weight: 900;
      }
    }
    &__sum {
      font-size: 24px;
    }
  }
  &-table {
    width: calc(100% - 400px);
    table {
      width: 100%;
      td, th {
        padding: 15px 5px;
        text-align: left;
        &:first-child {
          padding-left: 0;
        }
      }
      thead {
        border-bottom: 1px solid $gray-1;
      }
      th {
        color: $gray-1;
        font-weight: 500;
        font-size: .8em;
      }
    }
  }
  .options__product__price {
    font-size: 20px;
    font-weight: 700;
    margin: 0;
    padding: 0;
    white-space: nowrap;
  }
  .options__product {
    margin: 10px 0;
    &--img {
      width: 60px;
      height: 50px;
    }
    &--title {
      width: calc(100% - 60px);
      max-width: 200px;
      font-size: .9em;
    }
  }
  .cart-options__delete {
    position: static;
    top: auto;
    left: auto;
    transform: none;
    margin: 5px 0 0 0;
  }
}

.number {
  display: flex;
  align-items: center;
  span {
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: .5s;
    &.minus {
      background-image: url(../img/icons/minus.svg);
    }
    &.plus {
      background-image: url(../img/icons/plus.svg);
    }
    &:hover {
      opacity: .5;
    }
  }
  input {
    width: 50px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
  }
}

.order {
  padding-top: 80px;
  &-row {
    width: 100%;
    margin: 0;
    margin-top: -1px;
    @include horizonCenter;
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    padding: 25px 0;
    flex-wrap: wrap;
    & > * {
      margin: 10px 30px 10px 0;
      &:last-child {
        margin-right: 0;
      }
    }
    .form-field {
      font-weight: 600;
      max-width: calc(33.3% - 20px);
      flex: 1;
      input {
        font-weight: 400;
      }
      input, .nice-select {
        margin-bottom: 0;
      }
      .nice-select {
        border-radius: 30px;
      }
      &--address {
        max-width: calc(100% - 360px - 30px);
      }
      &--delivery {
        flex: 1;
        max-width: 360px;
        margin-right: 0;
      }
    }
    .order-row {
      padding: 0;
      border: none;
      margin: 0;
      & + .order-row {
        padding-top: 20px;
      }
    }
    &:last-of-type {
      border: none;
    }
  }
  .btn {
    width: auto;
    min-width: 220px;
  }
}

@media (max-width: 1199px) {

  .basket {
    &-info {
      padding: 20px;
      width: 280px;
    }
    &-table {
      width: calc(100% - 310px);
    }
  }

}

@media (max-width: 1023px) {

  .basket {
    display: block;
    &-info {
      padding: 20px;
      width: 100%;
    }
    &-table {
      width: 100%;
      margin-bottom: 30px;
    }
  }
  .order {
    padding-top: 30px;
  }
    .form_radio label {
      padding-top: 3px;
    }
    .order-row {
      .form-field {
        max-width: 100%;
        flex: auto;
        width: 100%;
        margin-right: 0;
        &--delivery {
          flex: none;
          max-width: 100%;
          margin-right: 0;
        }
        &--address {
          max-width: 100%;
          flex: none;
        }
      }

    }

}

@media (max-width: 735px) {

  .basket {
    &-table {
     table {
       display: block;
       thead {
         display: none;
       }
       tbody {
         display: block;
       }
       tr {
         padding: 10px 0;
         border-bottom: 1px solid $gray-2;
         display: flex;
         flex-wrap: wrap;
         align-items: center;
         justify-content: space-between;
         &:last-child {
           border: none;
         }
         td {
           padding-top: 5px;
           padding-bottom: 5px;
           padding-right: 10px;
           max-width: 33.3%;
           &:first-child {
             width: 100%;
             max-width: 100%;
             padding-right: 0;
           }
           .options-price--mob-hidden {
             display: none;
           }
         }
       }
     }
    }
  }
  .order {
    & > .order-row {
      &:last-child {
        justify-content: center;
        display: flex;
        flex-direction: column;
      }
      .consent {
        width: 200px;
      }
      & > * {
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }

}