.user-menu {
    // display: none;
    position: absolute;
    padding: 10px 20px 20px 20px;
    transition: .5s;
    top: 100%;
    right: 0;
    width: 200px;
    background-color: #fff;
    border-radius: 4px;
    text-align: right;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    transform: translateY(50px);
    box-shadow: 10px 10px 40px rgba(0,0,0,.15);
    overflow: auto;
    max-height: 60vh;
    
    ul {
        list-style: none;
        li {
            font-size: 14px;
            margin-top: 4px;
            // border-radius: 3px;
            // border-color: black;
            // border-style: solid;
        }
    }
    &.active {
        opacity: 1;
        visibility: visible;
        z-index: 3;
        transform: translateY(5px);
    }
}