
.delivery {
  margin: $indent-big;
  .text {
    width: 460px;
    max-width: 100%;
    padding-bottom: 200px;
  }
  &-img {
    position: absolute;
    bottom: -45px;
    left: 400px;
    max-height: 100%;
  }
}


@media (max-width: 700px) {

  .delivery-img {
    left: 0;
    max-width: 200%;
    max-height: 50%;
  }

}