
.footer {
  padding: 70px 0 35px 0;
  color: $white;
  font-size: .9em;
  background: #4f4f4f;
  a, button, input {
    color: $white;
  }
  .container {
    display: flex;
    align-items: flex-start;
    & > * {
      margin-bottom: 35px;
      margin-right: 35px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .menu {
    li {
      margin: 0 0 20px 0;
      a {
        font-weight: 500;
        color: $white;
        &:hover {
          opacity: .7;
        }
      }
    }
  }
  &-menu {
    min-width: 200px;
    flex: auto;
    &:nth-child(1) {
      .menu {
        column-count: 2;
        column-gap: 35px;
        page-break-inside: avoid;
        break-inside: avoid;
        li {
          page-break-inside: avoid;
          break-inside: avoid;
        }
      }
    }
  }
  &-contacts {
   width: 300px;
    &__item {
      margin: 20px 0;
      padding-left: 45px;
      img {
        position: absolute;
        top: 50%;
        left: 0;
        max-width: 25px;
        transform: translateY(-50%);
      }
    }
  }
  .social {
    display: none;
  }
}

@media (max-width: 1023px) {

  .footer {
    padding: 50px 0 0 0;
    .container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .footer-contacts {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
    &-menu {
      display: none;
    }
    &-contacts  {
      max-width: 100%;
      width: 300px;
    }
    h6 {
      text-align: center;
    }
    .social {
      display: flex;
      justify-content: center;
      a {
        margin: 0 15px;
      }
    }
  }

}
