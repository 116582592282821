
.advantages {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  &-item {
    text-align: center;
    width: 33.3%;
    font-size: 1.1em;
    font-weight: 500;
    &__icon {
      width: 100px;
      height: 100px;
      border-radius: $radius-1;
      border: 1px solid $red-2;
      margin: 0 auto 25px auto;
      padding: 10px;
      @include verticalCenter;
      transition: .5s;
      img {
        max-height: 100%;
        transition: .5s;
      }
    }

    //hover
    &:hover {
      .advantages-item__icon {
        border-color: $blue-3;
        img {
          transform: scaleX(-1) translateY(-10px);
        }
      }
    }
  }
}

.about {
  @include horizonCenterBetween;
  align-items: flex-start;
  &-text {
    padding-top: 30px;
    flex: 1;
    max-width: 460px;
    margin-bottom: 30px;
  }
  &-img {
    float: right;
    background: $white;
    border-radius: $radius-1;
    padding: 10px;
    text-align: center;
    width: 420px;
    max-width: 33%;
    margin-top: -50px;
    margin-bottom: 30px;
    @include shadow;
  }
}


@media (max-width: 735px) {

  .about {
    display: block;
    &-text {
      max-width: 100%;
      width: 100%;
    }
    &-img {
      margin: 0 auto;
      float: none;
      max-width: 90%;
      width: 270px;
    }
  }
  .advantages {
    justify-content: flex-start;
    &-item {
      font-size: .9em;
      text-align: left;
      width: 50%;
      margin-bottom: 20px;
      &__icon {
        width: 50px;
        height: 50px;
        margin-bottom: 10px;
        margin-right: 0;
        margin-left: 0;
      }
    }
  }


}