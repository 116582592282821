
.slider-home {

  .swiper-slide {
    height: auto;
    min-height: 500px;
    display: flex;
    overflow: hidden;
    border-radius: $radius-1;
  }
  .swiper-pagination {
    width: 45%;
    bottom: 50px;
    &-bullet {
      border-color: $white;
      &-active {
        border-color: $red-2;
      }
    }
  }
  &__name {
    width: 45%;
    padding: 60px 60px 130px 100px;
    color: $white;
    background-color: $green;
      span {
        font-weight: bold;
        font-size: 48px;
      }
  }
  &__product {
    padding: 60px;
    width: 55%;
    background-color: $yellow;
    display: flex;
    align-items: flex-end;
    &__img {
      width: 65%;
    }
    &__info {
      width: 35%;
    }
    .product-mark {
      margin-bottom: 25px;
    }
    &__title {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 20px;
      &:hover {
        color: $red-2;
      }
    }
    &__price {
      font-weight: 900;
      font-size: 30px;
      white-space: nowrap;
    }
  }
  .swiper-button {
    opacity: 1;
    background-color: transparent;
    background-image: url(../img/icons/arrow-next--black.svg), url(../img/next-mask.png);
    background-repeat: no-repeat, no-repeat;
    background-size: 15px, 100%;
    width: 46px;
    height: 106px;
    margin-top: -53px;
    border-radius: 0;
    &.swiper-button-prev {
      left: 0;
    }
    &.swiper-button-next {
      right: 0;
    }
  }
}

@media (max-width: 1023px) {

  .slider-home {
    padding-bottom: 35px;
    .swiper-button {
      display: none;
    }
    .swiper-slide {
      display: block;
      min-height: auto;
    }
    &__name {
      width: 100%;
      display: block;
      padding: 80px 30px;
      span {
        font-size: 34px;
      }
    }
    &__product {
      padding: 30px;
      width: 100%;
      &__img {
        img {
          max-height: 260px;
        }
      }
    }
    .swiper-pagination {
      width: 100%;
      bottom: 0;
      &-bullet {
        border-color: $gray-2;
      }
    }
  }
  .product-mark img {
    max-width: 90px;
  }

}

@media (max-width: 735px) {

  .slider-home {
    &__name {
      padding: 35px 30px;
      span {
        font-size: 24px;
        max-width: 80%;
      }
    }
    &__product {
      &__title {
        font-size: 12px;
        margin-bottom: 10px;
      }
      &__price {
      font-size: 18px;
    }
    }
    .product-mark {
      margin-bottom: 10px;
      img {
        max-width: 60px;
      }
    }
  }


}
