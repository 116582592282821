
.slider-news {
  margin: -10px;
  .swiper-container {
    padding: 10px;
    .swiper-slide {
      height: auto;
      transition: .5s;
    }
  }
  .news-preview {
    height: 100%;
    flex-direction: column;
    box-shadow: 5px 5px 10px rgba($black,.1);
    &__img {
      width: 100%;
      height: 170px;
      min-height: auto;
      &:before {
        display: none;
      }
    }
    &__text {
      width: 100%;
      padding: 30px;
    }
  }
}

@media (max-width:735px) {

  .slider-news {
    .swiper-container {
      .swiper-slide {
        width: 230px;
      }
    }
    .news-preview {
      &__img {
        height: 100px;
      }
      &__text {
        padding: 20px;
        a {
          font-size: .9em;
        }
      }
    }
  }

}
