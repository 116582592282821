
.category {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px;
  &-item {
    min-height: 100px;
    padding: 25px 35px;
    width: calc(33.3% - 40px);
    margin: 0 20px 20px 20px;
    @include shadow;
    background-color: $white;
    background-repeat: no-repeat;
    background-position: top left;
    background-image: url(../img/catalog/catalog.svg);
    border-radius: $radius-1;
    @include horizonCenter;
    justify-content: space-between;
    &__name {
      font-size: .9em;
      width: calc(100% - 30px);
      padding-right: 15px;
      max-width: 200px;
      text-transform: uppercase;
      font-weight: 700;
    }
    &__icon {
      width: 30px;
      text-align: center;
      img {
        transition: .5s;
      }
    }

    //hover
    &:hover {
      color: $red-2;
      .category-item__icon {
        img {
          transform: scaleX(-1);
        }
      }
    }
  }

  //head
  &-head {
    background: $gray-0;
    padding: 45px 60px;
    margin-bottom: 45px;
    &__title {
      padding-left: 30px;
      @include horizonCenterBetween;
      text-transform: uppercase;
      color: $gray-2;
      font-size: 12px;
      font-weight: 700;
      &:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 0;
        background: url(../img/icons/arrow-long--gray-left.svg) no-repeat center;
        width: 18px;
        height: 10px;
        transition: .5s;
      }
      .category-item__icon {
        width: 20px;
      }
      &:hover {
        &:before {
          transform: translateX(-10px);
        }
      }
    }
    ul {
      column-count: 4;
      column-gap: 35px;
      li {
        margin-bottom: 20px;
        a {
          font-size: .9em;
          color: $gray-3;
          &:hover {
            color: $black;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.category-head__title + ul {
  margin-top: 35px;
}

@media (max-width: 1023px) {

  .category {
    margin-right: -10px;
    margin-left: -10px;
    &-item {
      min-height: auto;
      padding: 20px 15px;
      width: calc(33.3% - 20px);
      margin: 0 10px 10px 10px;
    }
    //head
    &-head {
      padding: 30px 20px;
      margin-bottom: 35px;
      ul {
        column-count: 3;
        column-gap: 30px;
      }
    }
  }

}

@media (max-width: 735px) {

  .category {
    &-head {
      ul {
        column-count: 2;
        column-gap: 5px;
        li {
          margin-bottom: 10px;
          a {
            font-size: .8em;
          }
        }
      }
    }
  }

}

@media (max-width: 640px) {

  .category {
    &-item {
      width: calc(50% - 20px);
    }
  }

}
@media (max-width: 530px) {

  .category {
    &-item {
      width: calc(100% - 20px);
    }
  }

}